import { getStorage } from '@/utils';
import { baseURL } from "@/request/http.js";
import { apiCountyPotentialExport } from '@/request/api';
import { ElMessage } from 'element-plus';

export default ({ tableData, querys }) => {

    function exportExcel() {
        if (!tableData.value.length) {
            ElMessage.error("暂无数据，请搜索");
            return;
        }
        const token = getStorage("token");
        const href = `${baseURL}${apiCountyPotentialExport()}?token=${token}&code=one&isCity=${querys.isCity}`;
        const a = document.createElement("a");
        a.href = href;
        a.download = href;
        a.click();
    }

    return { exportExcel }
}