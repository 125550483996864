import { ref, reactive, watch } from 'vue';
import { apiCountyPotentialDisposable } from '@/request/api';
import withLoading from '@/utils/loading';

export default ({ viewType, isSearch, getWeightConfig, querys }) => {

    const pages = reactive({ pageNum: 1, pageSize: 100 });
    const total = ref(0);
    const tableData = ref([]);

    // 获取列表
    async function getList() {
        const params = { ...pages, ...querys, ...getWeightConfig() }
        const { data } = await withLoading(apiCountyPotentialDisposable)(params);
        total.value = data?.total || 0;
        tableData.value = data?.list || [];
    }

    // 分页
    watch(() => pages.pageNum, getList);

    // 搜索
    function search() {
        pages.pageNum = 1;
        getList();
    }

    // 切换显示方式监听
    watch(() => viewType.value, (newVal) => {
        if (newVal === 'list' && isSearch.value) {
            getList();
        }
    })

    return { pages, total, tableData, search }
}