export default ({ dicts, querys, pages, total, tableData, mapRef, isSearch }) => {

    function resetFn() {
        // 是否搜索过重置为false
        isSearch.value = false;
        // 重置选中参数
        querys.province = [];
        querys.city = [];
        querys.mainType = [];// 餐饮类型
        querys.cuisineType = [];// 菜系类型
        querys.cuisine = [];// 菜系
        querys.brandShopNumStart = null;// 门店数开始
        querys.brandShopNumEnd = null;// 门店数结束
        querys.brandPriceStart = null;// 客单价开始
        querys.brandPriceEnd = null;// 客单价结束
        querys.fromBatch = dicts.batch.list[0];// 目标批次
        querys.toBatch = '';// 对比批次
        querys.isCity = 1;// 统计对象 城市=1 区县=0
        // 重置数据
        pages.pageNum = 1;
        total.value = 0;
        tableData.value = [];
        // 重置地图
        mapRef.value && mapRef.value?.resetMap();
    }

    return { resetFn }
}