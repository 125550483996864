import { ref, watch, computed, nextTick } from 'vue';
import { apiCountyPotentialDisposable } from '@/request/api';
import withLoading from '@/utils/loading';
import { chunk, flatten } from 'loadsh';

export default ({ viewType, isSearch, getWeightConfig, querys }) => {

    const mapRef = ref();// 地图组件
    const bisection = ref(5);// 等分
    const pageSize = 5000;
    const mapData = ref([]);// 地图数据
    const colorListFive = ['#ee6666', '#fac858', '#91cc75', '#5470c6', '#73c0de'];
    const colorListhree = ['#ee6666', '#fac858', '#5470c6'];
    const colorList = computed(() => {
        let res = colorListFive;
        if (bisection.value === 3) {
            res = colorListhree;
        }
        return res;
    })
    /// 门店总数
    const shop_total = computed(() => {
        const list = mapData.value || [];
        let total = 0;
        list.forEach(item => {
            if (typeof item.shop_num === 'number') {
                total += item.shop_num
            }
        })
        return total.toLocaleString();
    })

    // 切换显示方式监听
    watch(() => viewType.value, (newVal) => {
        // 切换为地图时
        if (newVal === 'map') {
            nextTick(() => {
                mapRef.value.loadMap();// 加载地图，地图组件内部会判断是否已加载过，已加载过不会重复加载，但是也会触发加载完成事件
            })
        }
    }, { immediate: true })

    // 等分切换监听
    watch(() => bisection.value, () => {
        updateMap(bisectionHandle(mapData.value));
    })

    // 按照等分处理数据
    function bisectionHandle(arr) {
        const len = arr.length;
        const arg = Math.ceil(len / bisection.value);// 平均值
        const res = chunk(arr, arg);
        return flatten(res.map((item, index) => {
            item = item.map((child) => {
                child['color'] = colorList.value[index];
                return child;
            })
            return item;
        }))
    }

    // 获取地图全量数据
    async function getMapData() {
        const params = { pageNum: 1, pageSize, ...querys, ...getWeightConfig() }
        const { data } = await withLoading(apiCountyPotentialDisposable)(params);
        mapData.value = data?.list || [];
        if (!mapData.value.length) return;
        setTimeout(() => {
            updateMap(bisectionHandle(mapData.value));
        }, 0)
    }

    // 更新地图
    function updateMap(makers = []) {
        setTimeout(() => {
            mapRef.value.setMapCenter(makers[0].lon, makers[0].lat);// 设置中心点
            mapRef.value.addMakers(makers);
        }, 0)
    }

    // 地图加载监听
    function onMapInit() {
        if (isSearch.value) {
            getMapData();
        }
    }

    return { mapRef, bisection, getMapData, colorList, shop_total, onMapInit }
}