<template>
    <div id="container"></div>
</template>

<script setup>
import AMapLoader from '@amap/amap-jsapi-loader';
import { onBeforeUnmount } from 'vue';

const emits = defineEmits(['initComplete'])

let AMap = null;// 地图实例
let map = null; // 地图
const defaultZoom = 10;//默认缩放级别
const zooms = [2, 20]; //地图显示的缩放级别范围, 默认为 [2, 20] ，取值范围 [2 ~ 30]

// 加载地图
function loadMap() {
    if (AMap) {
        emits('initComplete');
        return;
    }
    AMapLoader.load({
        key: "b8740a32e7dbff3f65745a34c0c386a2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.ToolBar', 'AMap.ControlBar', 'AMap.HawkEye'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((_AMap) => {
        AMap = _AMap;
        initMap()
    }).catch(() => {
        // ElMessage.error('地图加载失败，请刷新浏览器，或更换浏览器后重试')
    })
}

// 初始化地图
function initMap() {
    map = new AMap.Map("container", {  //设置地图容器id
        viewMode: "2D",                //是否为3D地图模式
        zoom: defaultZoom,             //初始化地图级别
        zooms: zooms,                  //地图显示的缩放级别范围, 默认为 [2, 20] ，取值范围 [2 ~ 30]
        showLabel: true,              //是否显示标注（地名） 
    })
    // 地图图块加载完成后触发
    map.on('complete', () => {
        emits('initComplete');
        // 添加地图控件
        addControls()
    });
}

// 添加地图控件
function addControls() {
    // 比例尺
    map.addControl(new AMap.Scale());
    // 工具条
    map.addControl(new AMap.ToolBar({
        position: {
            top: '110px',
            left: '40px'
        }
    }));
    // 方向盘
    map.addControl(new AMap.ControlBar({
        position: {
            top: '10px',
            left: '10px',
        }
    }));
    // 鹰眼
    map.addControl(new AMap.HawkEye({
        opened: false
    }));
}

// 设置地图中心点
function setMapCenter(lng, lat) {
    map.setCenter([lng, lat], true)
}

// 批量标记点
function addMakers(makers = []) {
    map.clearMap();//移除所有覆盖物
    const arr = [];
    makers.forEach(item => {
        const m = new AMap.Marker({
            content: `<div class="my_maker"><span class="dot" style="background-color:${item.color}"></span><p>${item.district || item.city}</p></div>`,
            position: new AMap.LngLat(item.lon, item.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            anchor: 'bottom-center',
            data: item
        });
        // 移入事件
        m.on('mouseover', (ev) => {
            if (ev.target._originOpts.data) {
                addInfoWindow(ev.target._originOpts.data);
            }
        });
        // 移出事件
        m.on('mouseout', closeInfoWindow);
        arr.push(m);
    })
    map.add(arr);
    map.setZoom(7, true);
    // map.setFitView(
    //     arr,  // 覆盖物数组
    //     true,  // 动画过渡到制定位置
    //     [60, 60, 60, 60],  // 周围边距，上、下、左、右
    //     20,  // 最大 zoom 级别
    // );
}

// 打开信息框
function addInfoWindow(row) {
    const infoWindow = new AMap.InfoWindow({
        content: `<div>${row.city || ''}${row.district || ''}</div><div>门店数：${row.shop_num || '/'}</div>`,
        anchor: 'top-left',
    });
    // 在地图上打开信息窗体
    infoWindow.open(map, [row.lon, row.lat]);
}

// 关闭信息框
function closeInfoWindow() {
    map.clearInfoWindow();
}

// 重置地图
function resetMap() {
    map && map.destroy();
    map = null;
    initMap()
}

onBeforeUnmount(() => {
    map && map.destroy();
    AMap = null;
    map = null;
})

defineExpose({ loadMap, setMapCenter, addMakers, resetMap })
</script>

<style lang="scss" scoped>
#container {
    width: 100%;
    height: 100%;
}

:deep(.my_maker) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dot {
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #f2f2f2;
        opacity: 1;
    }

    p {
        color: #666;
        font-size: 12px;
        white-space: nowrap;
        text-shadow: 0 0 1px #fff;
        transform: scale(0.8);
    }
}

:deep(.amap-info-close) {
    display: none;
}
</style>