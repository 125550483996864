<template>
    <div class="district_one">
        <!-- 数据范围筛选 -->
        <div class="row_box">
            <div class="tit_box">
                <small-title tit="数据范围筛选"></small-title>
            </div>
            <!-- 参数 -->
            <div class="search_box">
                <div class="li">
                    <h6>省份：</h6>
                    <el-select v-model="querys.province" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.province.loading" @change="changeProvince">
                        <el-option v-for="item in dicts.province.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>城市：</h6>
                    <el-select v-model="querys.city" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.city.loading">
                        <el-option v-for="item in dicts.city.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>餐饮类型：</h6>
                    <el-select v-model="querys.mainType" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.mainType.loading" @change="changeMainType">
                        <el-option v-for="item in dicts.mainType.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系类型：</h6>
                    <el-select v-model="querys.cuisineType" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.cuisineType.loading" @change="changeCuisineType">
                        <el-option v-for="item in dicts.cuisineType.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系：</h6>
                    <el-select v-model="querys.cuisine" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.cuisine.loading">
                        <el-option v-for="item in dicts.cuisine.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>门店数范围：</h6>
                    <div class="range_input">
                        <el-input type="number" placeholder="门店数" v-model.number="querys.brandShopNumStart"></el-input>-
                        <el-input type="number" placeholder="门店数" v-model.number="querys.brandShopNumEnd"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>平均客单价范围</h6>
                    <div class="range_input">
                        <el-input type="number" placeholder="平均客单价" v-model.number="querys.brandPriceStart"></el-input>-
                        <el-input type="number" placeholder="平均客单价" v-model.number="querys.brandPriceEnd"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>目标季度：</h6>
                    <el-select v-model="querys.fromBatch" placeholder="目标季度" filterable :loading="dicts.batch.loading">
                        <el-option v-for="item in dicts.batch.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>对比季度：</h6>
                    <el-select v-model="querys.toBatch" placeholder="对比季度" filterable clearable
                        :loading="dicts.batch.loading">
                        <el-option v-for="item in dicts.batch.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>统计对象：</h6>
                    <el-radio-group v-model="querys.isCity">
                        <el-radio-button :label="1">城市</el-radio-button>
                        <el-radio-button :label="0">区县</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="btn_box">
                <el-button type="info" @click="resetFn">重置</el-button>
                <el-button type="primary" @click="onSearch">搜索</el-button>
            </div>
        </div>
        <div class="row_box">
            <div class="tit_box">
                <div class="le">
                    <small-title :tit="`一次性数据-${querys.isCity === 1 ? '城市' : '区县'}`"></small-title>
                    <p class="num_box" v-if="viewType === 'map'">门店总数：{{ shop_total }}</p>
                </div>
                <div class="ri_btn">
                    <el-radio-group size="medium" v-model="viewType">
                        <el-radio-button label="list">列表</el-radio-button>
                        <el-radio-button label="map">地图</el-radio-button>
                    </el-radio-group>
                    <el-button type="primary" size="medium" @click="exportExcel">导出excel<el-icon class="el-icon--right">
                            <Right />
                        </el-icon>
                    </el-button>
                </div>
            </div>
            <!-- list -->
            <div class="table_box" v-show="viewType === 'list'">
                <el-table :data="tableData" stripe border style="width: 100%" :header-cell-style="headerCellStyleHandle"
                    :cell-style="cellStyleHandle" height="600">
                    <el-table-column prop="city" label="城市" show-overflow-tooltip fixed="left" />
                    <el-table-column prop="district" label="区县" show-overflow-tooltip fixed="left" align="center"
                        v-if="querys.isCity === 0" />
                    <el-table-column prop="tier" label="Tier" show-overflow-tooltip fixed="left" align="center"
                        v-if="querys.isCity === 0" />
                    <el-table-column :label="oneTableTitle" align="center">
                        <el-table-column prop="total_score_" label="总得分" align="center" />
                        <el-table-column prop="scope_score_" label="规模总得分" align="center" />
                        <el-table-column prop="shop_num" label="餐饮门店数" align="center" />
                        <el-table-column prop="avg_comment_num" label="店均评论数" align="center" />
                        <el-table-column prop="avg_price" label="平均客单价" align="center" />
                        <el-table-column prop="growth_score_" label="成长总得分" align="center" />
                        <el-table-column prop="shop_num_inc_ratio" label="门店数增长率" align="center" />
                        <el-table-column prop="avg_price_inc_ratio" label="平均客单价增长率" align="center" />
                        <el-table-column prop="innovate_score_" label="创新总得分" align="center" />
                        <el-table-column prop="new_shop_ratio" label="新店率" align="center" />
                        <el-table-column prop="new_dish_ratio" label="新菜率" align="center" />
                        <el-table-column prop="new_dish_recommends_ratio" label="新菜推荐率" align="center" />
                    </el-table-column>
                </el-table>
                <div class="no_data" v-if="!tableData.length">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div class="page_box">
                    <el-pagination layout="prev, pager, next" background :total="total" :page-size="pages.pageSize"
                        v-model:current-page="pages.pageNum">
                    </el-pagination>
                </div>
            </div>
            <!-- map -->
            <div class="map_box" v-show="viewType === 'map'">
                <div class="bisection">
                    <p>点位颜色</p>
                    <el-radio-group v-model="bisection">
                        <el-radio-button :label="3">三等分</el-radio-button>
                        <el-radio-button :label="5">五等分</el-radio-button>
                    </el-radio-group>
                    <div class="color_box">
                        <span>高</span>
                        <ul>
                            <li v-for="item in colorList" :key="item" :style="{ backgroundColor: item }"></li>
                        </ul>
                        <span>低</span>
                    </div>
                </div>
                <map-container ref="mapRef" @initComplete="onMapInit"></map-container>
            </div>
        </div>
    </div>
</template> 
  
<script setup>
import { ref, computed } from "vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import { Right } from "@element-plus/icons-vue";
import useTableStyle from "./useTableStyle.js";
import MapContainer from './MapContainer.vue';// 地图容器
import useMap from './useMap';
import useParams from './useParams';
import useWeight from '../comp/useWeight';
import useReset from './useReset';
import useExportExcel from './useExportExcel';
import useTable from './useTable';

const viewType = ref('map');// 展示方式：list | map
const isSearch = ref(false);// 是否已经搜索

// 字典/参数
const { dicts, querys, changeProvince, changeMainType, changeCuisineType } = useParams();

// 一次性数据批次季度显示
const oneTableTitle = computed(() => {
    const { fromBatch, toBatch } = querys;
    return (fromBatch ? fromBatch : '目标季度') + ' / ' + (toBatch ? toBatch : '对比季度');
})

// 权重
const { getWeightConfig } = useWeight();

// table
const { pages, total, tableData, search } = useTable({ viewType, isSearch, getWeightConfig, querys });

// 导出
const { exportExcel } = useExportExcel({ tableData, querys });

// table样式
const { headerCellStyleHandle, cellStyleHandle } = useTableStyle();

// 地图
const { mapRef, bisection, colorList, getMapData, shop_total, onMapInit } = useMap({ viewType, isSearch, getWeightConfig, querys });

// 重置
const { resetFn } = useReset({ dicts, querys, pages, total, tableData, mapRef, isSearch });

// 点击搜索
function onSearch() {
    if (viewType.value === 'list') {
        search()
    } else if (viewType.value === 'map') {
        getMapData();
    }
    isSearch.value = true;
}

</script>
  
<style lang="scss" scoped>
.district_one {

    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .le {
                display: flex;
                align-items: center;

                .num_box {
                    padding-left: 30px;
                    color: #5470c6;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .ri_btn {
                display: flex;
                align-items: center;

                .el-button {
                    margin-left: 15px;
                }
            }
        }

        .search_box {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
            padding-top: 20px;

            .li {
                margin-right: 20px;
                margin-bottom: 10px;

                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }

                .el-select {
                    width: 200px;
                }

                :deep(.el-input__inner) {
                    border-radius: 7px;
                }

                .range_input {
                    display: flex;
                    align-items: center;

                    .el-input {
                        width: 98px;
                    }
                }

                &.two_select {
                    .el-select {
                        width: 150px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .btn_box {
            text-align: center;
            padding-top: 10px;
        }

        .table_box {
            position: relative;
            padding-top: 15px;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
        }

        .map_box {
            height: 700px;
            margin-top: 15px;
            position: relative;

            .bisection {
                background-color: #fff;
                position: absolute;
                top: 15px;
                right: 15px;
                z-index: 2;
                padding: 15px;
                box-shadow: 0 0 10px #ccc;
                border-radius: 4px;

                p {
                    padding-bottom: 5px;
                    color: #333;
                }

                .color_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;

                    span {
                        color: #666;
                        font-size: 12px;
                    }

                    ul {
                        display: flex;
                        margin: 0 2px;
                        border-radius: 2px;
                        overflow: hidden;

                        li {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    .page_box {
        text-align: right;
        padding: 20px 0;
    }
}

:deep(.el-table__empty-text) {
    display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
}</style>
  