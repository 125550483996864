export default function tableStyleHook() {
    // 头部单元格样式
    function headerCellStyleHandle(e) {
        const res = {
            background: "#f5f5f5",
            color: "#80899b",
            fontWeight: 400,
        };
        let label = e.column.label;
        if (
            label === "总得分" ||
            label === "规模总得分" ||
            label === "成长总得分" ||
            label === "创新总得分"
        ) {
            res.color = "#474e5c";
            res["fontWeight"] = "800";
        }
        return res;
    }

    // 单元格样式
    function cellStyleHandle(e) {
        const res = {
            color: "#80899b",
        };
        let label = e.column.label;
        if (
            label === "总得分" ||
            label === "规模总得分" ||
            label === "成长总得分" ||
            label === "创新总得分"
        ) {
            res.color = "#474e5c";
            res["fontWeight"] = "800";
        }
        return res;
    }
    return { headerCellStyleHandle, cellStyleHandle }
}